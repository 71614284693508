import { React } from 'react';
import { Button, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  div: {
    padding: '70% 30px'
  },
	buttonDiv: {
		textAlign: 'center',
    '& > *': {
      margin: theme.spacing(2),
			width: '50%'
    },
  },
}));

const SdcTech = () => {
  const classes = useStyles();

	function ButtonsComponent() {
		return (
			<div className={classes.buttonDiv}>
				<Button variant="contained">
					Default
				</Button>
				<Button variant="contained" color="primary">
					Primary
				</Button>
				<Button variant="contained" color="secondary">
					Secondary
				</Button>
				<Button variant="contained" disabled>
					Disabled
				</Button>
				<Button variant="contained" color="primary" href="#contained-buttons">
					Link
				</Button>
			</div>
		);
	}

  return (
    <div className={classes.div}>
			<ButtonsComponent />
    </div>
  );
};

export default SdcTech;
